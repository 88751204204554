import request from '@/utils/request';

export const list = (params) => {
    const rand = Date.now();
    return request.get(`app/VxAfterSales/list?${rand}`, { params });
}

export const download = (data) => {
    const rand = Date.now();
    return request.post(`app/VxAfterSales/download?${rand}`, data, {
        responseType:'blob'  // 一定要加
    });

}

export default {
    list,
    download
}