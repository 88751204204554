<template>
  <div>
    <div v-if="isList">
      <list @detail="onDetail" />
    </div>
    <div v-else>
      <detail :data="data" @back="goBack" />
    </div>
  </div>
</template>

<script>
import list from "./list";
import detail from "./detail";

export default {
  components: {
    list,
    detail
  },
  data () {
    return {
      data: null,
      isList: true
    }
  },
  methods: {
    onDetail (row) {
      this.isList = false;
      this.data = row;
    },
    goBack () {
      this.isList = true;
    }
  }
}
</script>

<style>
::v-deep .el-tabs__content {
  height: 100%!important;
}
</style>