<template>
  <div class="tabs-data-box tabs-data-box2">
    <el-form label-width="80px">
      <el-row>
        <el-col :span="span">
          <el-form-item label="设备名称">
            <el-input v-model="searchParams.headName" clearable size="mini" placeholder="请输入设备名称" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="设备编号">
            <el-input v-model="searchParams.code" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="设备类型">
            <el-select v-model="searchParams.deviceType" style="width:100%" clearable placeholder="请选择设备类型" size="mini" @change="onSearch">
              <el-option v-for="item in projectTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="客户公司">
            <el-input v-model="searchParams.company" clearable size="mini" placeholder="请输入客户公司" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="负责人">
            <el-input v-model="searchParams.tabulationName" clearable size="mini" placeholder="请输入负责人姓名" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="收货人">
            <el-input v-model="searchParams.consignee" clearable size="mini" placeholder="请输入收货人姓名" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="测试人员">
            <el-input v-model="searchParams.consignee" clearable size="mini" placeholder="请输入测试人员姓名" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="发货时间">
            <el-date-picker
              v-model="searchParams.deliveryTime"
              @change="onSearch"
              type="date"
              clearable
              size="mini"
              placeholder="选择发货时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="48 - 8 * span" class="right-btns">
          <div style="padding-top: 7px;">
            <el-button class="search-box-btn" icon="el-icon-search" size="mini" type="primary" @click="onSearch">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div v-loading="loading">
      <el-table :data="dataList" style="width: 100%" :height="tableHeight"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
          </template>
        </el-table-column>
        <el-table-column prop="headName" label="设备名称" align="center" width="200" />
        <el-table-column prop="code" label="设备编号" align="center" width="100" />
        <el-table-column prop="projectType" label="设备类型" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.deviceType === 0">主控</span>
            <span v-if="scope.row.deviceType === 1">总控</span>
            <span v-if="scope.row.deviceType === 2">保护板</span>
            <span v-if="scope.row.deviceType === 3">一体机</span>
          </template>
        </el-table-column>
        <el-table-column prop="company" label="客户公司" min-width="300">
          <template slot-scope="scope">
            <el-popover
              placement="top"
              width="270"
              trigger="hover"
              :content="scope.row.address">
              <div slot="reference" class="name-wrapper">
                【{{ scope.row.area }}】{{ scope.row.company }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="负责人">
          <el-table-column prop="tabulationName" label="姓名" align="center" width="80" />
          <el-table-column prop="headPhone" label="联系方式" align="center" width="110" />
        </el-table-column>
        <el-table-column label="收货人">
          <el-table-column prop="consignee" label="姓名" align="center" width="80" />
          <el-table-column prop="customerPhone" label="联系方式" align="center" width="110" />
        </el-table-column>
        <el-table-column label="测试人员">
          <el-table-column prop="testName" label="姓名" align="center" width="80" />
          <el-table-column prop="testPhone" label="联系方式" align="center" width="110" />
        </el-table-column>
        <el-table-column prop="deliveryTime" label="发货时间" align="center" width="100" />
        <el-table-column prop="remarks" label="备注" width="300" />
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import { getDeliveryWorkList } from "@/api/project";

const searchParams = {
  code: "",
  solveNumber: "",
  projectType: "",
  solveName: "",
  tabulationName: "",
  type: ""
};

export default {
  data () {
    return {
      utils: $utils,
      span: 4,
      dataList: [],
      pageData: {
        current: 1,
        total: 0,
        size: 20
      },
      searchParams: {},
      projectTypeList: $utils.PROJECT_TYPE_LIST,
      swTypeList: [
        {
          label: "解决",
          value: 0
        },
        {
          label: "未解决",
          value: 1
        },
        {
          label: "维修中",
          value: 3
        },
      ]
    }
  },
  created () {
    this.searchParams = searchParams;
    this.span = this.isSmallScreen ? 6 : 4;
    this.getDatas();
  },
  mounted () {
    this.tableHeight = document.body.scrollHeight - 250;
  },
  methods: {
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;

        const data = Object.assign({
          page:  this.pageData.current,
          size: this.pageData.size
        }, searchParams);

        if (data.deliveryTime) {
          data.deliveryTime = $utils.formatTime(data.deliveryTime, "YYYY-MM-DD 00:00:00");
        }

        const res = await getDeliveryWorkList(data);

        this.dataList = res.data;
        this.dataList.map(obj => {
          obj.id = Number(obj.id);
          return obj;
        });
        this.pageData = res.paging;
      } catch {
        this.dataList = [];
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>