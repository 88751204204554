var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "big-data-container",
    },
    [
      _c(
        "div",
        {
          staticClass: "content-list-data-box",
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "el-tabs",
            {
              staticClass: "device-detail-tabs",
              style: `height:${_vm.height - 30}px;`,
              attrs: { "tab-position": "left" },
              on: { "tab-click": _vm.onTabClick },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  staticStyle: { height: "100%" },
                  attrs: { label: "售后提报", name: "after-sales" },
                },
                [_vm.tabName === "after-sales" ? _c("after-sales") : _vm._e()],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticStyle: { height: "100%" },
                  attrs: { label: "维修工单", name: "service-work" },
                },
                [
                  _vm.tabName === "service-work"
                    ? _c("service-work")
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  staticStyle: { height: "100%" },
                  attrs: { label: "发货工单", name: "delivery-work" },
                },
                [
                  _vm.tabName === "delivery-work"
                    ? _c("delivery-work")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }