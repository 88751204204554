var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isList
      ? _c("div", [_c("list", { on: { detail: _vm.onDetail } })], 1)
      : _c(
          "div",
          [
            _c("detail", {
              attrs: { data: _vm.data },
              on: { back: _vm.goBack },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }