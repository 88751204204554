var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container-inner m-l-10" }, [
    _c(
      "div",
      [
        _c(
          "el-form",
          { attrs: { "label-width": "100px", "label-position": "left" } },
          [
            _c(
              "el-row",
              { attrs: { span: "24" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: "8" } },
                  [
                    _c("el-form-item", { attrs: { label: "客户公司" } }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "18px" } },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", clearable: "" },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchObj.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchObj, "companyName", $$v)
                              },
                              expression: "searchObj.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "4" } },
                  [
                    _c("el-form-item", { attrs: { label: "项目名称" } }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "18px" } },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", clearable: "" },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchObj.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchObj, "projectName", $$v)
                              },
                              expression: "searchObj.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "4" } },
                  [
                    _c("el-form-item", { attrs: { label: "提报人" } }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "18px" } },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", clearable: "" },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchObj.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchObj, "name", $$v)
                              },
                              expression: "searchObj.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "4" } },
                  [
                    _c("el-form-item", { attrs: { label: "联系方式" } }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "18px" } },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", clearable: "" },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchObj.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchObj, "tel", $$v)
                              },
                              expression: "searchObj.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "4" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户联系人" } },
                      [
                        _c("el-input", {
                          attrs: { size: "mini", clearable: "" },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchObj.contacts,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchObj, "contacts", $$v)
                            },
                            expression: "searchObj.contacts",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "8" } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "时间范围" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            align: "left",
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            "default-time": _vm.defaultTime,
                            "picker-options": _vm.pickerOptions,
                            size: "mini",
                            format: "yyyy-MM-dd",
                            clearable: false,
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.timeRange,
                            callback: function ($$v) {
                              _vm.timeRange = $$v
                            },
                            expression: "timeRange",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: "4" } },
                  [
                    _c("el-form-item", { attrs: { label: "客户电话" } }, [
                      _c(
                        "div",
                        { staticStyle: { "margin-right": "18px" } },
                        [
                          _c("el-input", {
                            attrs: { size: "mini", clearable: "" },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchObj.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchObj, "phone", $$v)
                              },
                              expression: "searchObj.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c("el-col", { attrs: { span: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "right-btns" },
                    [
                      _c(
                        "el-dropdown",
                        {
                          staticClass: "search-box-btn",
                          on: { command: _vm.onGetReport },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                icon: "el-icon-printer",
                                size: "mini",
                                type: "primary",
                              },
                            },
                            [_vm._v("导出报表")]
                          ),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "thispage" } },
                                [_vm._v("导出当前页")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "alls" } },
                                [_vm._v("导出所有")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "search-box-btn",
                          attrs: {
                            icon: "el-icon-search",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.onSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.table,
              height: _vm.height,
              "header-cell-style": {
                background: "#F7F7F7",
                color: "#2d2d2d",
                "text-align": "center",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "50",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.$index +
                              1 +
                              (_vm.pageData.current - 1) * _vm.pageData.size
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "提报时间",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "projectName",
                label: "项目名称",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "project",
                label: "项目编号",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "companyName",
                label: "客户公司",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "提报人",
                width: "100",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "tel",
                label: "联系电话",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "contacts",
                label: "客户联系人",
                width: "100",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "phone",
                label: "客户电话",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            icon: "el-icon-s-platform",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDetail(scope.row)
                            },
                          },
                        },
                        [_vm._v("查看详情")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticClass: "text-right m-t-10",
          attrs: {
            background: "",
            layout: "sizes, prev, pager, next, jumper",
            "page-sizes": [20, 50, 100],
            "page-size": _vm.pageData.size,
            total: _vm.pageData.total,
            "current-page": _vm.pageData.current,
          },
          on: {
            "size-change": _vm.onSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }