var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-data-box tabs-data-box2" },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备编号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请输入设备编号",
                        },
                        on: { change: _vm.onSearch },
                        model: {
                          value: _vm.searchParams.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "code", $$v)
                          },
                          expression: "searchParams.code",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "设备类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择设备类型",
                            size: "mini",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchParams.solveNumber,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "solveNumber", $$v)
                            },
                            expression: "searchParams.solveNumber",
                          },
                        },
                        _vm._l(_vm.projectTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "制表人" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请输入保修人员",
                        },
                        on: { change: _vm.onSearch },
                        model: {
                          value: _vm.searchParams.tabulationName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "tabulationName", $$v)
                          },
                          expression: "searchParams.tabulationName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "维修人员" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          size: "mini",
                          placeholder: "请输入保修人员",
                        },
                        on: { change: _vm.onSearch },
                        model: {
                          value: _vm.searchParams.solveName,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchParams, "solveName", $$v)
                          },
                          expression: "searchParams.solveName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "工单状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            clearable: "",
                            placeholder: "请选择工单状态",
                            size: "mini",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchParams.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "type", $$v)
                            },
                            expression: "searchParams.type",
                          },
                        },
                        _vm._l(_vm.swTypeList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "right-btns", attrs: { span: _vm.span } },
                [
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "7px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "search-box-btn",
                          attrs: {
                            icon: "el-icon-search",
                            size: "mini",
                            type: "primary",
                          },
                          on: { click: _vm.onSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.dataList,
                height: _vm.tableHeight,
                "header-cell-style": {
                  background: "#F7F7F7",
                  color: "#2d2d2d",
                  "text-align": "center",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.$index +
                                1 +
                                (_vm.pageData.current - 1) * _vm.pageData.size
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "code",
                  label: "设备编号",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "solveNumber",
                  label: "设备类型",
                  align: "center",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.solveNumber === 0
                          ? _c("span", [_vm._v("主控")])
                          : _vm._e(),
                        scope.row.solveNumber === 1
                          ? _c("span", [_vm._v("总控")])
                          : _vm._e(),
                        scope.row.solveNumber === 2
                          ? _c("span", [_vm._v("保护板")])
                          : _vm._e(),
                        scope.row.solveNumber === 3
                          ? _c("span", [_vm._v("一体机")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "companyName",
                  label: "所属公司",
                  "min-width": "300",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "project",
                  label: "所属项目",
                  "min-width": "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "tabulationName",
                  label: "制表人",
                  align: "center",
                  width: "80",
                },
              }),
              _c(
                "el-table-column",
                { attrs: { label: "维修人员" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "solveName",
                      label: "姓名",
                      align: "center",
                      width: "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "solvePhone",
                      label: "联系方式",
                      align: "center",
                      width: "110",
                    },
                  }),
                ],
                1
              ),
              _c("el-table-column", {
                attrs: {
                  prop: "money",
                  label: "费用",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "工单状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === 0
                          ? _c("span", [_vm._v("解决")])
                          : _vm._e(),
                        scope.row.type === 1
                          ? _c("span", [_vm._v("未解决")])
                          : _vm._e(),
                        scope.row.type === 3
                          ? _c("span", [_vm._v("维修中")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "repairLocation",
                  label: "维修地点",
                  "min-width": "300",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "repairStartTime",
                  label: "进场时间",
                  width: "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "repairCompleted",
                  label: "离场时间",
                  width: "160",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "remarks", label: "备注", width: "300" },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "text-right m-t-10",
            attrs: {
              background: "",
              layout: "sizes, prev, pager, next, jumper",
              "page-sizes": [20, 50, 100],
              "page-size": _vm.pageData.size,
              total: _vm.pageData.total,
              "current-page": _vm.pageData.current,
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }