<template>
  <div class="tabs-data-box tabs-data-box2">
    <el-form label-width="80px">
      <el-row>
        <el-col :span="span">
          <el-form-item label="设备编号">
            <el-input v-model="searchParams.code" clearable size="mini" placeholder="请输入设备编号" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="设备类型">
            <el-select v-model="searchParams.solveNumber" style="width:100%" clearable placeholder="请选择设备类型" size="mini" @change="onSearch">
              <el-option v-for="item in projectTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="制表人">
            <el-input v-model="searchParams.tabulationName" clearable size="mini" placeholder="请输入保修人员" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="维修人员">
            <el-input v-model="searchParams.solveName" clearable size="mini" placeholder="请输入保修人员" @change="onSearch" />
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="工单状态">
            <el-select v-model="searchParams.type" style="width:100%" clearable placeholder="请选择工单状态" size="mini" @change="onSearch">
              <el-option v-for="item in swTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span" class="right-btns">
          <div style="padding-top: 7px;">
            <el-button class="search-box-btn" icon="el-icon-search" size="mini" type="primary" @click="onSearch">查询</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <div v-loading="loading">
      <el-table :data="dataList" style="width: 100%" :height="tableHeight"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
          </template>
        </el-table-column>
        <el-table-column prop="code" label="设备编号" align="center" width="100" />
        <el-table-column prop="solveNumber" label="设备类型" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.solveNumber === 0">主控</span>
            <span v-if="scope.row.solveNumber === 1">总控</span>
            <span v-if="scope.row.solveNumber === 2">保护板</span>
            <span v-if="scope.row.solveNumber === 3">一体机</span>
          </template>
        </el-table-column>
        <el-table-column prop="companyName" label="所属公司" min-width="300" />
        <el-table-column prop="project" label="所属项目" min-width="100" />
        <el-table-column prop="tabulationName" label="制表人" align="center" width="80" />
        <el-table-column label="维修人员">
          <el-table-column prop="solveName" label="姓名" align="center" width="80" />
          <el-table-column prop="solvePhone" label="联系方式" align="center" width="110" />
        </el-table-column>
        <el-table-column prop="money" label="费用" align="center" width="100" />
        <el-table-column prop="type" label="工单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0">解决</span>
            <span v-if="scope.row.type === 1">未解决</span>
            <span v-if="scope.row.type === 3">维修中</span>
          </template>
        </el-table-column>
        <el-table-column prop="repairLocation" label="维修地点" min-width="300" />
        <el-table-column prop="repairStartTime" label="进场时间" width="160" align="center" />
        <el-table-column prop="repairCompleted" label="离场时间" width="160" align="center" />
        <el-table-column prop="remarks" label="备注" width="300" />
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import { getServiceWorkList } from "@/api/project";

const searchParams = {
  code: "",
  solveNumber: "",
  projectType: "",
  solveName: "",
  tabulationName: "",
  type: ""
};

export default {
  data () {
    return {
      utils: $utils,
      span: 4,
      dataList: [],
      pageData: {
        current: 1,
        total: 0,
        size: 20
      },
      searchParams: {},
      projectTypeList: $utils.PROJECT_TYPE_LIST,
      swTypeList: [
        {
          label: "解决",
          value: 0
        },
        {
          label: "未解决",
          value: 1
        },
        {
          label: "维修中",
          value: 3
        },
      ]
    }
  },
  created () {
    this.searchParams = searchParams;
    this.span = this.isSmallScreen ? 6 : 4;
    this.getDatas();
  },
  mounted () {
    this.tableHeight = document.body.scrollHeight - (this.isSmallScreen ? 320 : 205);
  },
  methods: {
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;

        const data = Object.assign({
          page:  this.pageData.current,
          size: this.pageData.size
        }, this.searchParams);

        const res = await getServiceWorkList(data);

        this.dataList = res.data;
        this.dataList.map(obj => {
          obj.id = Number(obj.id);
          return obj;
        });
        this.pageData = res.paging;
      } catch {
        this.dataList = [];
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>