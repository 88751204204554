<template>
  <div v-loading="loading" class="big-data-container">
      <div class="content-list-data-box" style="height:100%;">
        <el-tabs class="device-detail-tabs" tab-position="left" :style="`height:${height - 30}px;`" v-model="tabName" @tab-click="onTabClick">
        <el-tab-pane label="售后提报" name="after-sales" style="height: 100%">
          <after-sales v-if="tabName === 'after-sales'" />
        </el-tab-pane>
        <el-tab-pane label="维修工单" name="service-work" style="height: 100%">
          <service-work v-if="tabName === 'service-work'" />
        </el-tab-pane>
        <el-tab-pane label="发货工单" name="delivery-work" style="height: 100%">
          <delivery-work v-if="tabName === 'delivery-work'" />
        </el-tab-pane>
      </el-tabs>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AfterSales from "./after-sales";
import ServiceWork from "./service-work";
import DeliveryWork from "./delivery-work";

export default {
  computed: {
    ...mapGetters(['warnings', 'user']),
  },
  components: {
    AfterSales,
    ServiceWork,
    DeliveryWork,
  },
  data () {
    return {
      loading: false,
      height: 0,
      isAdmin: true,
      tabName: "after-sales",
      alarms: []
    }
  },
  watch: {
    warnings: function(v1, v2) {
      this.alarms = v1;
    }
  },
  created () {
    if (this.warnings) {
      this.alarms = this.warnings;
    }
    this.isAdmin = this.user.tenantId === 0;
    this.height = document.body.scrollHeight - 70;
    this.tabName = this.$route.query.tab || this.tabName;
  },
  methods: {
    onTabClick () {
      this.$route.query.tab = this.tabName;
    }
  }
}
</script>