<template>
  <div class="big-data-container-inner m-l-10">
    <div>
      <el-form label-width="100px" label-position="left">
        <el-row span="24">
          <el-col span="8">
            <el-form-item label="客户公司">
              <div style="margin-right: 18px;">
                <el-input size="mini" v-model="searchObj.companyName" clearable @change="onSearch" />
              </div>
            </el-form-item>
          </el-col>
          <el-col span="4">
            <el-form-item label="项目名称">
              <div  style="margin-right: 18px;">
                <el-input size="mini" v-model="searchObj.projectName" clearable @change="onSearch" />
              </div>
            </el-form-item>
          </el-col>
          <el-col span="4">
            <el-form-item label="提报人">
              <div  style="margin-right: 18px;">
                <el-input size="mini" v-model="searchObj.name" clearable @change="onSearch" />
              </div>
            </el-form-item>
          </el-col>
          <el-col span="4">
            <el-form-item label="联系方式">
              <div  style="margin-right: 18px;">
                <el-input size="mini" v-model="searchObj.tel" clearable @change="onSearch" />
              </div>
            </el-form-item>
          </el-col>
          <el-col span="4">
            <el-form-item label="客户联系人">
              <el-input size="mini" v-model="searchObj.contacts" clearable @change="onSearch" />
            </el-form-item>
          </el-col>
          <el-col span="8">
            <el-form-item label="时间范围">
              <el-date-picker
                v-model="timeRange"
                type="datetimerange"
                align="left"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="defaultTime"
                :picker-options="pickerOptions"
                size="mini"
                format="yyyy-MM-dd"
                :clearable="false"
                @change="onSearch"
              />
            </el-form-item>
          </el-col>
          <el-col span="4">
            <el-form-item label="客户电话">
              <div  style="margin-right: 18px;">
                <el-input size="mini" v-model="searchObj.phone" clearable @change="onSearch" />
              </div>
            </el-form-item>
          </el-col>
          <el-col span="12">
            <div class="right-btns">
              <el-dropdown class="search-box-btn" @command="onGetReport">
                <el-button icon="el-icon-printer" size="mini" type="primary" style="width:100%">导出报表</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="thispage">导出当前页</el-dropdown-item>
                  <el-dropdown-item command="alls">导出所有</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button class="search-box-btn" icon="el-icon-search" size="mini" type="primary" @click="onSearch">查询</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div v-loading="loading">
      <el-table :data="table" style="width: 100%;" :height="height"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="提报时间" width="180" align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="project" label="项目编号" width="120" align="center"></el-table-column>
        <el-table-column prop="companyName" label="客户公司" align="center"></el-table-column>
        <el-table-column prop="name" label="提报人" width="100" align="center"></el-table-column>
        <el-table-column prop="tel" label="联系电话" width="120" align="center"></el-table-column>
        <el-table-column prop="contacts" label="客户联系人" width="100" align="center"></el-table-column>
        <el-table-column prop="phone" label="客户电话" width="120" align="center"></el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" icon="el-icon-s-platform" @click="onDetail(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import afterSalesApi from "@/api/after-sales";

import TimerPickerSettingsMixin from "@/views/mixin/TimerPickerSettingsMixin";

let pageData = {
  current: 1,
  size: 20,
  total: 0
};

export default {
  mixins: [ TimerPickerSettingsMixin ],
  data () {
    return {
      pageData: pageData,
      dlPageData: {
        size: 0,
        page: 0
      },
      table: [],
      height: 0,
      isList: true,
      row: null,
      searchObj: {
        companyName: "",
        projectName: "",
        name: "",
        tel: "",
        contacts: "",
        phone: ""
      }
    }
  },
  created () {
    console.log("after-sales-list");
    this.height = document.body.scrollHeight - 240;
    this.getDatas();
  },
  methods: {
    onSearch () {
      pageData.page = 1;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;

        const [ createTime, updateTime ] = this.getSearchTime();

        const params = Object.assign({
          page: pageData.current,
          size: pageData.size,
          createTime, updateTime
        }, this.searchObj);

        const res = await afterSalesApi.list(params);

        let data = res.data;

        this.table = data;
        pageData = res.paging;
      } catch (err) {
        this.table = [];
        this.pageData = pageData = {
          current: 1,
          size: 20,
          total: 0
        };
      } finally {
        this.loading = false;
      }
    },
    onDetail (row) {
      this.$emit("detail", row);
    },
    onGetReport (command) {

      if ($utils.userReadOnly(this.user)) {
        this.$message.error("当前用户没有下载权限");
        return;
      }

      switch (command) {
        case "thispage":
          this.dlPageData.page = pageData.current;
          this.dlPageData.size = pageData.size;
          this.doExport();
          break;
        case "alls":
          this.dlPageData.page = 1;
          this.dlPageData.size = 99999999;
          this.doExport();
          break;
      }
    },
    async doExport () {

      const [ createTime, updateTime ] = this.getSearchTime();

      const queryData = Object.assign({
        size: this.dlPageData.size,
        page: this.dlPageData.page,
        createTime, updateTime
      }, this.searchObj);

      const res = await afterSalesApi.download(queryData);

      $utils.exportExcel(res);
    },
    getSearchTime () {
      let [ createTime, updateTime ] = this.timeRange;
      createTime = $utils.formatTime(createTime, "YYYY-MM-DD 00:00:00");
      updateTime = $utils.formatTime(updateTime, "YYYY-MM-DD 23:59:59");

      return [ createTime, updateTime ];
    }
  }
}
</script>