var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container-inner" }, [
    _c("div", { staticClass: "content-inner" }, [
      _c(
        "div",
        { staticStyle: { height: "calc(100% - 42px)" } },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-position": "left", "label-width": "100px" },
            },
            [
              _c(
                "el-row",
                { attrs: { span: "24" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: "4" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提报时间" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.createTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "createTime", $$v)
                              },
                              expression: "form.createTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "8" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户公司" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.companyName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "companyName", $$v)
                              },
                              expression: "form.companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectName", $$v)
                              },
                              expression: "form.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目编号" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.project,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "project", $$v)
                              },
                              expression: "form.project",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "提报人" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系方式" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.tel,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tel", $$v)
                              },
                              expression: "form.tel",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户联系人" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.contacts,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contacts", $$v)
                              },
                              expression: "form.contacts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户电话" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: { size: "mini", readonly: true },
                            model: {
                              value: _vm.form.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "5px", height: "100px" },
                      attrs: { span: "24" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "问题描述" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: {
                              size: "mini",
                              autosize: { minRows: 5, maxRows: 5 },
                              type: "textarea",
                              readonly: true,
                            },
                            model: {
                              value: _vm.form.information,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "information", $$v)
                              },
                              expression: "form.information",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticClass: "m-t-20",
                      staticStyle: { height: "100px" },
                      attrs: { span: "24" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "解决方案" } },
                        [
                          _c("el-input", {
                            staticStyle: { "padding-right": "10px" },
                            attrs: {
                              size: "mini",
                              autosize: { minRows: 5, maxRows: 5 },
                              type: "textarea",
                              readonly: true,
                            },
                            model: {
                              value: _vm.form.solution,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "solution", $$v)
                              },
                              expression: "form.solution",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "提报配图" } }, [
                _c(
                  "div",
                  { staticClass: "after-sales-picture-box" },
                  _vm._l(_vm.form.picture, function (picture) {
                    return _c(
                      "div",
                      { key: picture },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "after-sales-picture-img",
                            attrs: {
                              src: picture,
                              "preview-src-list": _vm.form.picture,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "placeholder" },
                                slot: "placeholder",
                              },
                              [
                                _c("i", { staticClass: "el-icon-loading" }),
                                _vm._v(" 图片加载中"),
                                _c("span", { staticClass: "dot" }, [
                                  _vm._v("..."),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-btns m-t-10" },
        [
          _c(
            "el-button",
            {
              attrs: {
                icon: "el-icon-refresh-left",
                size: "mini",
                type: "primary",
              },
              on: { click: _vm.goBack },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }