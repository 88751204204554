import request from '@/utils/request';

// 获取项目信息
export function getProjects(params) {
  const rand = Date.now();
  return request.get(`projects?${rand}`, { params });
}

// 更新项目
export function editProject(data) {
  const rand = Date.now();
  return request.post(`projects/project?${rand}`, data);
}

// 新增项目
export function addProjcet(data) {
  const rand = Date.now();
  return request.put(`projects/project?${rand}`, data);
}

// 项目详情
export function getProjectDetail(id) {
  const rand = Date.now();
  return request.get(`projects/${id}?${rand}`);
}

// 维修工单列表
export function getServiceWorkList(params) {
  const rand = Date.now();
  return request.get(`serviceWork/List?${rand}`, { params });
}
// 维修工单详情
export function getServiceWorkDetail(id) {
  const rand = Date.now();
  return request.get(`serviceWork/${id}?${rand}`);
}

// 发货工单列表
export function getDeliveryWorkList(params) {
  const rand = Date.now();
  return request.get(`deliveryWork/List?${rand}`, { params });
}
// 发货工单详情
export function geDeliveryWorkDetail(id) {
  const rand = Date.now();
  return request.get(`deliveryWork/${id}?${rand}`);
}
// 新增发货工单
export function addDeliveryWork(data) {
  const rand = Date.now();
  return request.post(`/deliveryWork/save?${rand}`, data);
}