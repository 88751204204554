<template>
  <div class="big-data-container-inner">
    <div class="content-inner">
        <div style="height: calc(100% - 42px);">
          <el-form ref="form" label-position="left" label-width="100px">
            <el-row span="24">
              <el-col span="4">
                <el-form-item label="提报时间">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.createTime" />
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item label="客户公司">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.companyName" />
                </el-form-item>
              </el-col>
              <el-col span="12">
                <el-form-item label="客户地址">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.address" />
                </el-form-item>
              </el-col>
              <el-col span="12">
                <el-form-item label="项目名称">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.projectName" />
                </el-form-item>
              </el-col>
              <el-col span="12">
                <el-form-item label="项目编号">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.project" />
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item label="提报人">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.name" />
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item label="联系方式">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.tel" />
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item label="客户联系人">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.contacts" />
                </el-form-item>
              </el-col>
              <el-col span="6">
                <el-form-item label="客户电话">
                  <el-input size="mini" style="padding-right:10px;" :readonly="true" v-model="form.phone" />
                </el-form-item>
              </el-col>
              <el-col span="24" style="margin-top:5px; height:100px;">
                <el-form-item label="问题描述">
                  <el-input size="mini" style="padding-right:10px;" :autosize="{ minRows: 5, maxRows: 5}" type="textarea" :readonly="true" v-model="form.information" />
                </el-form-item>
              </el-col>
              <el-col span="24" class="m-t-20" style="height:100px;">
                <el-form-item label="解决方案">
                  <el-input size="mini" style="padding-right:10px;" :autosize="{ minRows: 5, maxRows: 5}" type="textarea" :readonly="true" v-model="form.solution" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="提报配图">
              <div class="after-sales-picture-box">
                <div v-for="picture in form.picture" :key="picture">
                  <el-image 
                    :src="picture"
                    class="after-sales-picture-img"
                    :preview-src-list="form.picture">
                    <div slot="placeholder" class="image-slot">
                      <i class="el-icon-loading"></i> 图片加载中<span class="dot">...</span>
                    </div>
                  </el-image>
                </div>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="right-btns m-t-10">
          <el-button icon="el-icon-refresh-left" size="mini" type="primary" @click="goBack">返回</el-button>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      form: {
        name: "",
        tel: "",
        information: "",
        companyName: "",
        project: "",
        createTime: "",
        picture: []
      }
    }
  },
  created () {
    console.log("after-sales-detail");
    this.form = this.data;
    this.getDatas();
  },
  methods: {
    async getDatas () {
      if (this.form.picture) {
        this.form.picture = this.form.picture.split(",");
      } else {
        this.form.picture = [];
      }
      this.form.picture = this.form.picture.map(picture => {
        let url = `${process.env.VUE_APP_BASE_URL}/wxapp/upload/${picture}.png`;
        if (!$utils.isProductionMode()) {
          url = `	https://hc.i-hc.cn${url}`;
        }
        return url;
      });
    },
    goBack () {
      this.$emit("back");
    }
  }
}
</script>
<style lang="scss" scoped>


.after-sales-picture-box {
  >div {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 33%;
    height: 230px;
  }

  >div:hover {
    background: #F2F6FC;
  }

  .after-sales-picture-img {
    max-height: 200px;
    line-height: 200px;
    margin: 15px;
  }

  ::v-deep .el-image__inner {
    vertical-align: middle;
  }
}
</style>